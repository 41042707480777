import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const USER_ENDPOINT = 'user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  getAll() {
    return this.httpClient.get(`${environment.claroApiUrl}${USER_ENDPOINT}/${environment.actions.all}`);
  }

  show(id: number) {
    return this.httpClient.get(`${environment.claroApiUrl}${USER_ENDPOINT}/${environment.actions.show}/${id}`);
  }

  getResellers() {
    return this.httpClient.get(`${environment.claroApiUrl}${USER_ENDPOINT}/${environment.actions.getResellers}`);
  }

  store(user: any) {
    return this.httpClient.post(`${environment.claroApiUrl}${USER_ENDPOINT}/${environment.actions.store}`, user);
  }

  update(id: number, user: any) {
    return this.httpClient.put(`${environment.claroApiUrl}${USER_ENDPOINT}/${environment.actions.update}/${id}`, user);
  }

  updateMyData(user: any) {
    return this.httpClient.put(`${environment.claroApiUrl}${USER_ENDPOINT}/${environment.actions.updateMyProfile}`, user);
  }

  updateByAdmin(id: number, user: any) {
    return this.httpClient.put(`${environment.claroApiUrl}${USER_ENDPOINT}/${environment.actions.updateByAdmin}/${id}`, user);
  }

  updateNicknameByAdmin(id: number, nickname: any) {
    return this.httpClient.put(`${environment.claroApiUrl}${USER_ENDPOINT}/${environment.actions.updateNicknameByAdmin}/${id}`, nickname);
  }

  changePassword(credentials: any) {
    return this.httpClient.put(`${environment.claroApiUrl}${USER_ENDPOINT}/${environment.actions.changePassword}`, credentials);
  }

  activate(id: number) {
    return this.httpClient.put(`${environment.claroApiUrl}${USER_ENDPOINT}/${environment.actions.activate}/${id}`, {});
  }

  deactivate(id: number) {
    return this.httpClient.put(`${environment.claroApiUrl}${USER_ENDPOINT}/${environment.actions.inactive}/${id}`, {});
  }

  delete(id: number) {
    return this.httpClient.delete(`${environment.claroApiUrl}${USER_ENDPOINT}/${environment.actions.delete}/${id}`);
  }

}
