import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const POOL_ENDPOINT = 'pool';
const USER_POOL_ENDPOINT = 'user-pool';

@Injectable({
  providedIn: 'root'
})
export class PoolService {

  private searchPool$ = new BehaviorSubject<string>('');
  getSearchPool(): Observable<string> {
    return this.searchPool$.asObservable();
  }
  setSearchPool(searchPool: string) {
    this.searchPool$.next(searchPool);
  }

  constructor(private httpClient: HttpClient) { }

  getAll() {
    return this.httpClient.get(`${environment.claroApiUrl}${POOL_ENDPOINT}/${environment.actions.all}`);
  }

  GetResellersPools() {
    return this.httpClient.get(`${environment.claroApiUrl}${POOL_ENDPOINT}/${environment.actions.myPools}`);
  }

  show(id: number) {
    return this.httpClient.get(`${environment.claroApiUrl}${POOL_ENDPOINT}/${environment.actions.show}/${id}`);
  }

  getCountedPools() {
    return this.httpClient.get(`${environment.claroApiUrl}${POOL_ENDPOINT}/${environment.actions.countedList}`);
  }

  getRankingByWeek(poolId: number, weekId: number) {

    let data = {
      pool: +poolId,
      week: weekId
    }
    return this.httpClient.post(`${environment.claroApiUrl}user-pick/all-ranking`, data);
  }

  getPositions(id: number) {
    return this.httpClient.get(`${environment.claroApiUrl}${POOL_ENDPOINT}/${environment.actions.positions}/${id}`);
  }

  store(pool: any) {
    return this.httpClient.post(`${environment.claroApiUrl}${POOL_ENDPOINT}/${environment.actions.store}`, pool);
  }

  update(id: number, pool: any) {
    return this.httpClient.put(`${environment.claroApiUrl}${POOL_ENDPOINT}/${environment.actions.update}/${id}`, pool);
  }

  updateByReseller(id: number, pool: any) {
    return this.httpClient.put(`${environment.claroApiUrl}${POOL_ENDPOINT}/${environment.actions.updateByReseller}/${id}`, pool);
  }

  activate(id: number) {
    return this.httpClient.put(`${environment.claroApiUrl}${POOL_ENDPOINT}/${environment.actions.activate}/${id}`, {});
  }

  deactivate(id: number) {
    return this.httpClient.put(`${environment.claroApiUrl}${POOL_ENDPOINT}/${environment.actions.deactivate}/${id}`, {});
  }

  delete(id: number) {
    return this.httpClient.delete(`${environment.claroApiUrl}${POOL_ENDPOINT}/${environment.actions.delete}/${id}`);
  }

  deleteParticipant(poolId: number, userId: number) {
    return this.httpClient.delete(`${environment.claroApiUrl}${USER_POOL_ENDPOINT}/${environment.actions.deleteUser}/?pool_id=${poolId}&user_id=${userId}`);
  }

  importExcel(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(`${environment.claroApiUrl}${POOL_ENDPOINT}/${environment.actions.import}`, formData);
  }

}
